/* Utility: promo button block
-------------------------------------------------- */
.promo-button {
    position: relative;
}

.promo-button .btn {
    background-color: transparent;
}

.promo-button .popover {
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0 1px 5px rgba(0,0,0,0.2);
}

.promo-button .popover.visible {
    display: inline-block;
    left: 50%;
    transform: translate(-50%);
    z-index: 1000;
}

.promo-button .sticker {
    padding-left: 5px;
    padding-right: 5px;
    display: inline
}

.promo-button .tnt-svg {
    top: 2px;
    position: relative;
}

.promo-button .notifications-tip {
    min-width: 200px;
    text-align: center;
}

.promo-button .notifications-tip h5 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 5px;
}

.promo-button .notifications-tip p {
    margin: 0;
    font-size: 13px;
    line-height: 1.5em;
}     

.promo-button .sticker .tnt-svg{
    font-size: 14px;
}

.promo-button .btn-lg .sticker .tnt-svg{
    font-size: 16px;
}

.promo-button .notifications-tip p.light {
    color: rgba(255,255,255,.8);
}

.promo-button .notifications-tip p.dark {
    color: rgba(0,0,0,.8);
}